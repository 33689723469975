import React from "react";
import Tile from '../tile';
import { TileSize } from "../tile/types";

function Results({
  loading,
  hasError,
  results,
}: {
  loading: boolean;
  hasError: boolean;
  results: any;
}) {
  if (loading || hasError) return null;

  if (!results.length)
    return <div className="mb-40 text-slate-500 text-lg">Sorry, no results were found for the selected filters.</div>;

  return (
    <div className="md:grid md:grid-cols-2 lg:grid-cols-4 md:gap-6">
      {results.map((result: any, key: number) => {
        let badge = null;

        if (result.contentType?.singularName || result.eventDate) {
          badge = {
            text: result.contentType?.singularName,
            date: result.eventDate
          }
        }

        return (
          <Tile
            url={result.url}
            intlLink={true}
            date={result.publishDate}
            title={result.title}
            image={result.image}
            size={TileSize.Square}
            badge={badge}
            count={key}
            key={key}
          />
        )
      })}
    </div>
  );
}

export default Results;
