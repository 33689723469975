import React, { useEffect, useRef, useState } from "react";
import SVG from "react-inlinesvg";
import queryString from "query-string";
import { navigate } from "gatsby";

export const SearchTextContext: any = React.createContext(null);

function SearchField({ label, children }: { label?: string; children: any }) {
  const [value, setValue] = useState<string | null>(null);
  const refs: any = {
    field: useRef(null),
  };

  const isSSR = typeof document === "undefined";

  function setHistory(query: string) {
    const currentQuery = location.search;
    const currentURL = location.href.replace(`${currentQuery}`, "");
    const filterQuery = `${currentURL}${query}`;

    history.pushState({}, document.title, filterQuery);
  }

  function setQuery() {
    const params = queryString.parse(location.search);
    const q = params.q && (typeof params.q === "object" ? params.q[0] : params.q);
    setValue(q ? q : "");
  }

  const onClear = () => {
    const field = refs.field.current;
    field.value = "";
    setValue(field.value);
  };

  const onSubmit = (event: any) => {
    event.preventDefault();
    const field = refs.field.current;
    setValue(field.value);
  };

  const updateQueryParam = () => {
    const params = queryString.parse(location.search);

    if (value && value !== "") {
      params["q"] = value;
    } else if (params.q) {
      delete params.q;
    }

    const stringified = queryString.stringify(params);

    if (stringified) {
      navigate(`?${stringified}`);
    } else {
      navigate("?");
    }
  };

  useEffect(() => {
    setQuery();
  }, []);

  useEffect(() => {
    const field = refs.field.current;
    field.value = value;
    updateQueryParam();
  }, [value]);

  if (!isSSR) {
    useEffect(() => {
      if (document.location.search) {
        setQuery();
      } else {
        onClear();
      }
    }, [document.location.search]);
  }

  return (
    <SearchTextContext.Provider value={value}>
      <div className="mb-6 md:mb-12">
        <form className="relative w-full md:flex" onSubmit={onSubmit}>
          <div className="flex justify-center items-center w-full">
            <div>
              <div className="mr-6">
                <SVG
                  src="/images/icons/search.svg"
                  className="w-6 h-6 md:w-8 md:h-8"
                  title="Search icon"
                  cacheRequests={true}
                />
              </div>
            </div>
            <div className="w-full">
              <input
                ref={refs.field}
                type="text"
                placeholder="Type here..."
                className="w-full font-semibold bg-transparent text-2xl md:text-5xl border-0 focus:ring focus:ring-transparent placeholder-slate-400 p-0"
                defaultValue={label}
                maxLength={50}
              />
            </div>
            <div className="md:mr-3">
              <button
                type="button"
                className="transition-colors duration-100 leading-tight rounded-full p-6 px-3 text-sm font-semibold text-white bg-transparent underline text-slate-400"
                onClick={onClear}
              >
                Clear
              </button>
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="transition-colors duration-100 leading-tight rounded-full p-4 px-8 text-md font-medium text-white bg-slate-400 hover:bg-slate-500 w-full text-center"
            >
              Search
            </button>
          </div>
        </form>
      </div>
      {children}
    </SearchTextContext.Provider>
  );
}

export default SearchField;
