import React, { useContext, useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import get from "lodash/get";
import algoliasearch from "algoliasearch/lite";
import useInfiniteScroll from "react-infinite-scroll-hook";
import useTree, { CollectionType } from "../../hooks/useTree";
import { Contentful_ContentTypeCollection } from "graphql-types";
import Filter, { GroupType } from "../filter";
import { FilterContext } from "../filter/context";
import { algoliaFilters, getFilters } from "../filter/utils";
import Loading from "../collection/loading";
import Results from "./results";
import Error from "../collection/error";
import SearchField, { SearchTextContext } from "./search-field";
import { ReorderFilter } from "../../utils/reorder-filter";

const client = algoliasearch(process.env.ALGOLIA_APP_ID ?? "", process.env.ALGOLIA_APP_SEARCH_KEY ?? "");
const index = client.initIndex(process.env.ALGOLIA_SITE_SEARCH_INDEX ?? "");

function Collection() {
  const filterContext: GroupType[] = useContext(FilterContext);
  const searchTextContext: string = useContext(SearchTextContext);
  const [pageIndex, setPageIndex] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [infiniteLoading, setInfiniteLoading] = useState(true);
  const [results, setResults] = useState<any>([]);
  function loadMore() {
    setInfiniteLoading(true);
    search();
  }

  const infiniteRef = useInfiniteScroll({
    loading: infiniteLoading,
    hasNextPage,
    onLoadMore: loadMore,
  });

  function search(reset = false) {
    let filters = algoliaFilters(filterContext);
    setHasError(false);

    if (reset) {
      setLoading(true);
      setInfiniteLoading(false);
      setPageIndex(0);
      setHasNextPage(false);
    }

    index
      .search(searchTextContext, {
        facetFilters: [
          getFilters("contentType", filters),
          getFilters("researchAreasCollection", filters),
          getFilters("sectorsCollection", filters),
        ].filter((e) => e.length),
        hitsPerPage: 40,
        page: reset ? 0 : pageIndex,
      })
      .then(({ hits, nbPages, page }: { hits: any; nbPages: number; page: number }) => {
        if (reset) setResults([...hits]);
        else setResults([...results, ...hits]);

        setTimeout(() => {
          setLoading(false);
          setInfiniteLoading(false);
          setPageIndex(page + 1);
          setHasNextPage(nbPages - 1 > page);
        }, 500);
      })
      .catch(() => {
        setLoading(false);
        setHasError(true);
        setHasNextPage(false);
        setPageIndex(0);
      });
  }

  useEffect(() => {
    if (searchTextContext !== null) {
      search(true);
    }
  }, [searchTextContext, filterContext]);

  return (
    <>
      <Error hasError={hasError} />
      <Loading loading={loading} />
      <div ref={infiniteRef}>
        <Results loading={loading} hasError={hasError} results={results} />
        <Loading loading={infiniteLoading} max={4} />
      </div>
    </>
  );
}

function SiteSearch() {
  const data = useStaticQuery(siteSearchQuery);
  const contentTypes: Contentful_ContentTypeCollection = get(data, "filter.contentTypeCollection");
  const sectorsData = [...useTree(CollectionType.sectors, null, true, false)];
  const researchAreasData = [...useTree(CollectionType.researchAreas, null, true, false)];
  const groups: GroupType[] = [
    {
      groupLabel: "Content Type",
      ref: "contentType", // used for Algolia reference
      items: contentTypes.items,
    },
    {
      groupLabel: "Research Area",
      ref: "researchAreasCollection", // used for Algolia reference
      items: researchAreasData,
    },
    {
      groupLabel: "Sector",
      ref: "sectorsCollection", // used for Algolia reference
      items: sectorsData,
    },
  ];

  return (
    <div className="container mx-auto px-6 py-14">
      <SearchField>
        <Filter groups={ReorderFilter(groups)}>
          <Collection />
        </Filter>
      </SearchField>
    </div>
  );
}

// export default PeopleSearch;
export default React.memo(SiteSearch);

const siteSearchQuery = graphql`
  query siteSearchQuery {
    channel: contentful {
      podcastChannelCollection {
        items {
          sys {
            id
            publishedVersion
          }
        }
      }
    }
    filter: contentful {
      contentTypeCollection(order: name_ASC) {
        items {
          sys {
            id
            publishedVersion
          }
          name
          singularName
          childrenCollection {
            items {
              label
              title
              description
              sys {
                id
                publishedVersion
              }
            }
          }
        }
      }
    }
  }
`;
